import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { ARCHERY_HOURS, DATE_KEY_FORMAT } from '../../services/constants';
import SuperSaaSWidget from '../../components/SuperSaaSWidget';

import { getSpecialClosures } from '../../services/firebase';

const mod = (n, m) => {
  return ((n % m) + m) % m;
}

const FORECAST_LENGTH = 7;

const Archery = () => {
  const heroImage = '/public/images/archery-hero-min.jpg';
  const membershipUrl = '/membership';
  const contactUrl = '/contact';
  // const breakingNews = 'Youth Archery to begin Oct 7, parental supervision is required for now';
  const breakingNews = '';

  const [hours] = useState(ARCHERY_HOURS);
  const [thisWeek, setThisWeek] = useState([]);
  const [operations, setOperations] = useState();
  const [specialClosures, setSpecialClosures] = useState({});
  const [specialClosure, setSpecialClosure] = useState({});

  const updateSpecialClosures = async () => {
    const closures = await getSpecialClosures();
    setSpecialClosures(closures);
  }
  const updateForecast = () => {
    const today = moment();
    const day = mod(today.day() - 1, 7);
    setOperations(hours[day]);
    const dateKey = moment().format(DATE_KEY_FORMAT);
    let specialClosure = specialClosures[dateKey];
    specialClosure = specialClosure && specialClosure.range.indexOf('archery') > -1 ? specialClosure : null;
    const startOfWeek = today;

    const thisWeek = [];
    for (let i = 0; i < FORECAST_LENGTH; i++) {
      const day = today.day() - 1;
      const date = startOfWeek.clone().add(i, 'days').format(DATE_KEY_FORMAT);
      const dailyOp = hours[(day + i) % 7];
      const isTemporarilyClosed = specialClosures[date];
      thisWeek.push({
        ...dailyOp,
        date,
        isTemporarilyClosed: isTemporarilyClosed && isTemporarilyClosed.range.indexOf('archery') > -1,
        reason: isTemporarilyClosed && isTemporarilyClosed.reason
      })
    }

    setSpecialClosure(specialClosure);
    setThisWeek(thisWeek)
  }


  useEffect(() => {
    updateSpecialClosures();
    const script = document.createElement('script');

    script.src = "https://use.typekit.net/foobar.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    updateForecast();
  }, [specialClosures])

  return (
    <div className="archery page">
      <div className="page-banner" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="page-banner-text">
          <div className="is-operational">
            <h1>RRGC Archery Range</h1>
            {operations &&
              <h2 style={{ color: operations.isOpen ? 'white' : 'lightgrey' }}>
                {specialClosure ? 'Closed For Today' : operations.isOpen ? `Open from ${operations.hours} today` : 'Closed Today'}
              </h2>
            }
            <>
              <p className="special-closure">{specialClosure ? specialClosure.reason : ''}</p>
              <p className="date">{moment().format('dddd, MMMM Do, YYYY')}</p>
            </>
          </div>
        </div>
      </div>
      {breakingNews && (
        <section className="breaking-news positive">
          <div className="container">
            <h3>{breakingNews}</h3>
          </div>
        </section>
      )}
      <div className="container">
        <div className="introduction-block">
          <div className="left-block">
            <div className="location-block">
              <a href="https://g.page/rrgcarcheryandairgun?share" target="_blank">
                <div className="location-icon-container">
                  <i className="fa fa-map-marker-alt"></i>
                </div>
                <div className="location-info-container">
                  <p>
                    <span className="heading">Archery & Airgun Range</span>
                    <br />
                    7400 River Rd Suite 140
                    <br />
                    Richmond, BC,
                    <br />
                    Canada
                  </p>
                  <p className="directions">
                    Directions <i className="fa fa-caret-right"></i>
                  </p>
                </div>
              </a>
            </div>
            <div className="hours">
              <h2 style={{ paddingLeft: '30px' }}>Hours This Week</h2>
              {thisWeek.map((entry, index) => (
                <div className={`hours-day ${index === 0 ? 'current' : ''} ${entry.isTemporarilyClosed || !entry.isOpen ? 'closed' : ''}`}>
                  <div className="day">
                    {entry.label}
                    <div className='date'>{entry.date}</div>
                  </div>
                  <div className='opening-hours'>
                    <div>
                      {entry.isTemporarilyClosed ? 'Temporarily Closed' : entry.hours}
                    </div>
                    {entry.isTemporarilyClosed &&
                      <div className='reason'>{entry.reason}</div>
                    }
                  </div>
                </div>
              ))}
              <p className="disclaimer">Hours subject to change depending on Range Officer availability.</p>
            </div>
            <div className="archery-schedule">
              <SuperSaaSWidget />
            </div>
          </div>
          <div className="right-content">
            <div className="introduction-text">
              <h2>Richmond Rod and Gun Club Archery Range</h2>
              <p>
              Here at RRGC we welcome all skill levels and bow types. 
              Recurve, compound, beginner, expert it doesn’t matter!  
              Our range is equipped with 8 full lanes accommodating up to 16 archers on a single line.  
              If needed we do have the option of running a double line system doubling our capacity! 
              We have bow racks to hold your equipment while you retrieve your arrows.
              
              </p>
              <h3>We now accept contactless payment options!</h3>
              <div className="columns">
                <p className="two-column-cell">
                  <i className="fa fa-cc-visa"></i> <i className="fa fa-cc-mastercard"></i> <i className="fa fa-cc-amex"></i> Credit
                </p>
                <p className="two-column-cell">
                  <i className="fa fa-money"></i> Cash
                </p>
                
                <p className="two-column-cell">
                  <i className="fas fa-credit-card"></i> Interac Debit
                </p>
              </div>
            </div>
            <div className="archery-pricing-text">
              <h2>Pricing</h2>
              <div className="columns">
                <p className="two-column-cell">Members Drop-In
                </p>
                <p className="two-column-cell">$6
                </p>
                <p className="two-column-cell">Non-Members Drop-In
                </p>
                <p className="two-column-cell">$18
                </p>
                <p className="two-column-cell">Non-Members Youth Drop-In
                </p>
                <p className="two-column-cell">$12
                </p>
                <p className="two-column-cell">Beginner Lessons
                </p>
                <p className="two-column-cell">$40
                </p>
                <p className="two-column-cell">Equipment Rental
                  <p style={{ margin: '0', marginLeft: '10px' }}className="disclaimer">(bow, arrows, finger tab, arm guard)</p>
                </p>
                <p className="two-column-cell">$12
                </p>
                <p className="two-column-cell">Extra 3 Arrows
                </p>
                <p className="two-column-cell">$6
                </p>
                <p className="two-column-cell">Targets
                </p>
                <p className="two-column-cell">$1 - 40cm (full or 3-spot)<br></br>
                $3 - 60cm<br></br>
                $5 - 80cm
                </p>
                <p className="two-column-cell">Private Events
                  <p style={{ margin: '0', marginLeft: '10px' }}className="disclaimer">Corporate, Birthdays, etc</p>
                </p>
                <p className="two-column-cell">Please Contact Us
                </p>
              </div>
              <p className="disclaimer">Prices are in Canadian Dollars</p>
            </div>
            <div className="archery-pricing-text">
              <h2>Coaching<p style={{ margin: '0', marginLeft: '10px' }}className="disclaimer">Available with RRGC Membership </p></h2>
                  
              <div className="columns">
                <p className="two-column-cell">Archery Fundamentals Program
                </p>
                <p className="two-column-cell">$200
                </p>
                <p className="two-column-cell">Drop-in Coaching
                </p>
                <p className="two-column-cell">$30
                </p>
                <p className="two-column-cell">1-on-1 Coaching
                </p>
                <p className="two-column-cell">$60
                </p>
              </div>
              <p className="disclaimer">Prices are in Canadian Dollars</p>
            </div>
            <div className="new-shooters">
              <h2>New to Archery? Get started quickly with no commitment!</h2>
              <p>
              New to archery? Or it’s been a long time since you’ve shot?
              We do ask you to register for one of our beginner lessons to go over the range rules and safety.
              Currently we run our beginner lessons on Monday and you can register here or check the sidebar for scheduling details.
              We currently have space for 4 beginners so please pre-book!
              Here is what you can expect when signing up for a beginner’s lesson:
              </p>
              <ul>
              <li>7:30 pm - Arrival, sign in and get equipment fitted</li>
              <li>8:00 pm - Range rules, safety briefing, basics of shooting with a dedicated coach</li>
              <li>8:30 - 9:30 pm - Shoot and have fun!</li>
              </ul>
              <p>Make sure you arrive on time! We will hold pre-registered beginner lesson slots until 7:45, after which we will fill spaces on a first-come, first-served basis.</p>
              <div className="benefit-box">
                <div className="benefits">
                  <i className="fas fa-chalkboard-teacher"></i>
                  <div className="text">Beginner Lessons on Monday</div>
                </div>
                <div className="benefits">
                  <i className="fas fa-check"></i>
                  <div className="text">Equipment Rentals Available</div>
                </div>
                <div className="benefits">
                  <i className="fas fa-user-friends"></i>
                  <div className="text"> Private Group Beginner Lessons Available*</div>
                </div>
              </div>

              
              <p className="disclaimer">*For parties larger than 4, please <a href={contactUrl}>contact us</a> for private sessions.</p>
              <p>We still run regular drop in on Monday nights as well! So don’t worry if you want to drop in on Mondays.</p>
            </div>
            <div className="experienced-shooters">
              <h2>Experienced Archers Welcome!</h2>
              <p>
              If you're looking for a friendly archery community and fun range to be a part of, RRGC Archery is the place for you. 
              We have rental equipment if you need. 
              This includes recurve bows, arrows, finger tabs, and arm guards.
              </p>
              <div className="benefit-box">
                <div className="benefits">
                  <i className="fas fa-hand-holding-usd"></i>
                  <div className="text">
                    <span style={{ fontWeight: 'bold', fontSize: '18px' }}>$6</span> Drop-in Fee for{' '}
                    <a href={membershipUrl}>Members</a>
                  </div>
                </div>
                <div className="benefits">
                  <i className="fas fa-bullseye"></i>
                  <div className="text">Adjustable 18m Target Lanes</div>
                </div>
                <div className="benefits">
                  <i className="fas fa-check"></i>
                  <div className="text">Equipment Rentals Available</div>
                </div>
                <div className="benefits">
                  <i className="fas fa-users"></i>
                  <div className="text">Friendly Range Officers</div>
                </div>
              </div>

              <div className="what-you-get">
                <p className="line-with-icon">
                  <i className="fas fa-dollar-sign"></i> $6 per session for{' '}
                  <a href={membershipUrl}>RRGC Members</a>
                </p>
                <p className="line-with-icon">
                  <i className="fas fa-dollar-sign"></i> $18 per session for{' '}
                  <a href={membershipUrl}>General Public</a>
                </p>
                <p className="line-with-icon">
                  <i className="fas fa-clock"></i> Two Hours per Session
                </p>
                <p className="line-with-icon">
                  <i className="fas fa-check"></i> All-inclusive Equipment Rentals from $12 per session**
                </p>
                <p className="line-with-icon">
                  <i className="fas fa-road"></i> Up to 8 shooting lanes
                </p>
                <p className="line-with-icon">
                  <i className="fas fa-thumbs-up"></i> High Ceiling Clearance
                </p>
                <p className="line-with-icon">
                  <i className="fas fa-parking"></i> Free Parking
                </p>
                <p className="line-with-icon">
                  <i className="fas fa-chalkboard-teacher"></i> Archery range competency required***
                </p>
              </div>
              <p className="disclaimer">** Archery equipment rentals $12, includes bow, arrows, finger tab and armguards</p>
              <p className="disclaimer">
                *** Beginner Lessons / Prior Training outside RRGC is also accepted as proof of competency.
              </p>
              <p>Safety is our top priority.</p>
              <p>
                Due to the speed and level of autonomy our drop-in nights run at, we kindly ask all drop-in archers to
                have at least a certain level of range-safety and archery competency.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Archery;
