/* IMPORTANT - must start on a MONDAY */
export const ARCHERY_HOURS = [
  { label: "Monday", isOpen: true, hours: "7:30pm - 9:30pm (Beginner Lessons + Drop-in)" },
  { label: "Tuesday", isOpen: true, hours: "7:30pm - 9:30pm (First Tuesday of each month is closed)" },
  { label: "Wednesday", isOpen: true, hours: "7:30pm - 9:30pm (League Night Oct.-Mar.)" },
  { label: "Thursday", isOpen: true, hours: "7:30pm - 9:30pm (Open Drop-in)" },
  { label: "Friday", isOpen: true, hours: "7:30pm - 9:30pm (Open Drop-in)" },
  { label: "Saturday", isOpen: true, hours: "3:00pm - 5:00pm (Youth Only)" },
  //{ label: "Saturday", isOpen: false, hours: "Closed" },
  { label: "Sunday", isOpen: false, hours: "Closed" },
];

/*
export const AIRGUN_HOURS = [
  { label: 'Monday', isOpen: false, hours: 'Closed' },
  { label: 'Tuesday', isOpen: false, hours: 'Closed' },
  { label: 'Wednesday', isOpen: false, hours: 'Closed' },
  { label: 'Thursday', isOpen: true, hours: '7:00pm - 9:00pm' },
  { label: 'Friday', isOpen: false, hours: 'Closed' },
  { label: 'Saturday', isOpen: false, hours: 'Closed' },
  { label: 'Sunday', isOpen: false, hours: 'Closed' }
];

export const ARCHERY_HOURS = [
  { label: 'Monday', isOpen: false, hours: 'Closed' },
  { label: 'Tuesday', isOpen: false, hours: 'Closed' },
  { label: 'Wednesday', isOpen: false, hours: 'Closed' },
  { label: 'Thursday', isOpen: false, hours: 'Closed' },
  { label: 'Friday', isOpen: false, hours: 'Closed' },
  { label: 'Saturday', isOpen: false, hours: 'Closed' },
  { label: 'Sunday', isOpen: false, hours: 'Closed' }
];
*/
export const AIRGUN_HOURS = [
  { label: "Monday", isOpen: false, hours: "Closed" },
  { label: "Tuesday", isOpen: true, hours: "7:00pm - 9:00pm (Athletes Only)" },
  { label: "Wednesday", isOpen: true, hours: "7:00pm - 9:00pm (Athletes Match Night)" },
  { 
    label: "Thursday", 
    isOpen: true, 
    hours: ["Athletes: 6:30pm - 9:00pm", "Drop-ins: 7:30pm - 9:00pm"] 
  },
  { label: "Friday", isOpen: false, hours: "Closed" },
  { label: "Saturday", isOpen: false, hours: "Closed" },
  { 
    label: "Sunday", 
    isOpen: true, 
    hours: ["Athletes: 11:00am - 1:00pm"]
    //hours: ["Athletes: 11:00am - 1:00pm", "Drop-ins: 1:00pm - 3:00pm"] 
  }
];

export const MAX_ARCHERY_ARCHERS = 5;
export const MAX_ARCHERY_SPECTATORS = 1;
export const DATE_KEY_FORMAT = "MM/DD/YYYY";
export const GROUP_SEPARATOR = "Drop-ins"; //Used to define 2nd group to use line break before group name
